
export default {
  data () {
    return {
      items: [
        {
          icon: '/images/phone-icon.jpg',
          link: 'tel:061-018-3040,2',
          text: '061-018-3040-2',
          title: 'Phone Icon'
        },
        {
          icon: '/images/email-icon.jpg',
          link: 'mailto:support@locopack.co',
          text: 'support@locopack.co',
          title: 'Email Icon'
        },
        {
          icon: '/images/facebook-icon.jpg',
          link: 'https://www.facebook.com/locopack.co',
          text: 'locopack.co',
          title: 'Facebook Icon'
        },
        {
          icon: '/images/line-icon.jpg',
          link: 'https://lin.ee/bkli1RI',
          text: '@locopack',
          title: 'Line Icon'
        }
      ]
    }
  },
  mounted () {
  },
  computed: {
    consentLink () {
      // const currentLang = this.$i18n.locale
      return process.env.SCGP_CONSENT_LINK
    },
    privacyLink () {
      // const currentLang = this.$i18n.locale
      return process.env.SCGP_PRIVACY
    }
  }
}
